import { Observable } from 'rxjs';
import {
  TaxonomyCategoryCreateDto,
  TaxonomyCategoryDto,
  TaxonomyCategoryEditDto,
  TaxonomyCategoryGetAllDto,
  TaxonomyCategoryPageDto,
  TaxonomyCategoryPageQueryParamsDto,
  TaxonomyCategoryShowDto,
} from '../dtos/taxonomy-category.dto';

export abstract class TaxonomyCategoryRepository {
  abstract getPage(
    params: TaxonomyCategoryPageQueryParamsDto
  ): Observable<TaxonomyCategoryPageDto>;
  abstract getAll(): Observable<TaxonomyCategoryGetAllDto>;
  abstract create(
    params: TaxonomyCategoryCreateDto
  ): Observable<TaxonomyCategoryDto>;
  abstract update(
    params: TaxonomyCategoryEditDto
  ): Observable<TaxonomyCategoryDto>;
  abstract delete(params: { id: number }): Observable<TaxonomyCategoryDto>;
  abstract show(params: any): Observable<TaxonomyCategoryShowDto>;
}
